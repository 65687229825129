







import Vue from "vue"
import LoginBadge from "./LoginBadge.vue"
export default Vue.extend({
  name: "AuditorBadge",
  components: { LoginBadge }
})
