var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LoginBadge",
    { staticClass: "ml-2 ml-1 auditor-badge" },
    [
      _c("SvgIcon", {
        staticClass: "mr-2 ml-1",
        attrs: { width: "24", height: "24", name: "binoculars" },
      }),
      _c("span", { staticClass: "mr-2" }, [_vm._v(" Auditor ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }